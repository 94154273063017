import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Card, CardColumns, Container } from 'react-bootstrap'
import { PageTitle } from '../components/pagetitle'
import SEO from '../components/seo'

const Services = props => (
  <Layout>
    <SEO title="Servizi" />
    <main>
      <Container>
        <PageTitle>
          I nostri <span className="accent">servizi</span>
        </PageTitle>
        <CardColumns>
          <Card>
            <Img
              title="Riparazione PC"
              fluid={props.data.riparazione.childImageSharp.fluid}
            />
            <Card.Body>
              <Card.Title>Riparazione PC</Card.Title>
              <Card.Text>
                Il PC non si accende più? Hai malfunzionamenti o rallentamenti?
                Tempo di aggiornare il sistema operativo? Il servizio rapido di
                riparazione che cercavi!
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Img
              title="Consulenza informatica"
              fluid={props.data.consulenza.childImageSharp.fluid}
            />
            <Card.Body>
              <Card.Title>Consulenza informatica</Card.Title>
              <Card.Text>
                Vuoi acquistare computer o altri dispositivi o sei indeciso
                quale soluzione software adottare? Chiedi un consiglio a degli
                esperti per evitare brutte sorprese
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Img
              title="Monitoraggio"
              fluid={props.data.monitoraggio.childImageSharp.fluid}
            />
            <Card.Body>
              <Card.Title>Monitoraggio infrastruttura</Card.Title>
              <Card.Text>
                Non farti cogliere impreparato da problematiche
                nell'infrastruttura, con le nostre soluzioni potrai visionare
                costantemente lo stato della tua infrastruttura
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Img
              title="Networking"
              fluid={props.data.networking.childImageSharp.fluid}
            />
            <Card.Body>
              <Card.Title>Progettazione e installazione reti</Card.Title>
              <Card.Text>
                Che si tratti di una nuova installazione o di un ammodernamento
                di un'infrastruttura già esistente,
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Img
              title="Vendita"
              fluid={props.data.vendita.childImageSharp.fluid}
            />
            <Card.Body>
              <Card.Title>Vendita componenti</Card.Title>
              <Card.Text>
                Vendiamo componenti e siamo disponibili a effettuare
                l'assemblaggio di pc desktop con componenti scelti da voi
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Img
              title="Installazione servizi"
              fluid={props.data.installazione.childImageSharp.fluid}
            />
            <Card.Body>
              <Card.Title>Installazione servizi</Card.Title>
              <Card.Text>
                Installazione di servizi come posta, monitoraggio rete, proxy e
                firewall presso l'infrastruttura del cliente
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Img
              title="Sistemi di videosorveglianza"
              fluid={props.data.videosorveglianza.childImageSharp.fluid}
            />
            <Card.Body>
              <Card.Title>Sistemi di videosorveglianza</Card.Title>
              <Card.Text>
                Installa un sistema di sorveglianza, per tenere monitorata la
                tua azienda anche quando non ci sei.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Img
              title="Progettazione siti internet"
              fluid={props.data.website.childImageSharp.fluid}
            />
            <Card.Body>
              <Card.Title>Progettazione siti internet</Card.Title>
              <Card.Text>
                Incrementa il numero dei tuoi clienti con un sito web moderno e
                reattivo. Rilancia il tuo brand con internet.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Img
              title="Recupero dati"
              fluid={props.data.recovery.childImageSharp.fluid}
            />
            <Card.Body>
              <Card.Title>Recupero dati</Card.Title>
              <Card.Text>
                Hai dimenticato di effettuare il backup dei tuoi dati e il PC
                non parte più? Faremo il possibile per recuperarli!
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Img
              title="Realizzazione programmi"
              fluid={props.data.programmazione.childImageSharp.fluid}
            />
            <Card.Body>
              <Card.Title>Realizzazione programmi</Card.Title>
              <Card.Text>
                Realizzazione di piccoli programmi aziendali ad-hoc per le
                vostre necessità.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Img
              title="Social media e SEO"
              fluid={props.data.social.childImageSharp.fluid}
            />
            <Card.Body>
              <Card.Title>Social media e SEO</Card.Title>
              <Card.Text>
                Migliorate la visibilità del vostro brand mediante la creazione
                di pagine sui social media e mediante l'ottimizzazione dei
                motori di ricerca.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Img
              title="Realizzazione chatbot"
              fluid={props.data.chatbot.childImageSharp.fluid}
            />
            <Card.Body>
              <Card.Title>Realizzazione chatbot</Card.Title>
              <Card.Text>
                Realizzazione di piccoli bot che rispondono ai vostri clienti in
                chat o che vi invieranno in automatico segnalazioni e notifiche
                tramite il vostro programma di chat preferito.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Img
              title="Verifica sicurezza"
              fluid={props.data.sicurezza.childImageSharp.fluid}
            />
            <Card.Body>
              <Card.Title>Verifica sicurezza</Card.Title>
              <Card.Text>
                Non farti cogliere impreparato da attacchi di hacker black hat.
                Accertati che la tua infrastruttura sia protetta con gli ultimi
                aggiornamenti, backup e con le adeguate protezioni per tenere al
                sicuro i dati aziendali.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardColumns>
      </Container>
    </main>
  </Layout>
)

export default Services

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    riparazione: file(relativePath: { eq: "riparazione.jpg" }) {
      ...fluidImage
    }
    consulenza: file(relativePath: { eq: "consulenza.jpg" }) {
      ...fluidImage
    }
    monitoraggio: file(relativePath: { eq: "monitoraggio.jpg" }) {
      ...fluidImage
    }
    networking: file(relativePath: { eq: "networking.jpg" }) {
      ...fluidImage
    }
    vendita: file(relativePath: { eq: "vendita.jpg" }) {
      ...fluidImage
    }
    installazione: file(relativePath: { eq: "installazione.jpg" }) {
      ...fluidImage
    }
    sicurezza: file(relativePath: { eq: "sicurezza.jpg" }) {
      ...fluidImage
    }
    videosorveglianza: file(relativePath: { eq: "videosorveglianza.jpg" }) {
      ...fluidImage
    }
    programmazione: file(relativePath: { eq: "programmazione.jpg" }) {
      ...fluidImage
    }
    website: file(relativePath: { eq: "website.jpg" }) {
      ...fluidImage
    }
    recovery: file(relativePath: { eq: "recovery.jpg" }) {
      ...fluidImage
    }
    chatbot: file(relativePath: { eq: "chatbot.jpg" }) {
      ...fluidImage
    }
    social: file(relativePath: { eq: "social.jpg" }) {
      ...fluidImage
    }
  }
`
